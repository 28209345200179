<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="主表id" prop="mainId">
      <el-input v-model="dataForm.mainId" placeholder="主表id"></el-input>
    </el-form-item>
    <el-form-item label="接受人" prop="userId">
      <el-input v-model="dataForm.userId" placeholder="接受人"></el-input>
    </el-form-item>
    <el-form-item label="上报内容" prop="reportContent">
      <el-input v-model="dataForm.reportContent" placeholder="上报内容"></el-input>
    </el-form-item>
    <el-form-item label="接收人属于区域" prop="areaCode">
      <el-input v-model="dataForm.areaCode" placeholder="接收人属于区域"></el-input>
    </el-form-item>
    <el-form-item label="接受时间" prop="aceptDate">
      <el-input v-model="dataForm.aceptDate" placeholder="接受时间"></el-input>
    </el-form-item>
    <el-form-item label="下达时间" prop="transmitDate">
      <el-input v-model="dataForm.transmitDate" placeholder="下达时间"></el-input>
    </el-form-item>
    <el-form-item label="上报时间" prop="reportDate">
      <el-input v-model="dataForm.reportDate" placeholder="上报时间"></el-input>
    </el-form-item>
    <el-form-item label="上报附件" prop="reportFile">
      <el-input v-model="dataForm.reportFile" placeholder="上报附件"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olfmajortramit.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        dataForm: {
          id: null,
          mainId: '',
          userId: '',
          reportContent: '',
          areaCode: '',
          aceptDate: '',
          transmitDate: '',
          reportDate: '',
          reportFile: ''
        },
        dataRule: {
          mainId: [
            { required: true, message: '主表id不能为空', trigger: 'blur' }
          ],
          userId: [
            { required: true, message: '接受人不能为空', trigger: 'blur' }
          ],
          reportContent: [
            { required: true, message: '上报内容不能为空', trigger: 'blur' }
          ],
          areaCode: [
            { required: true, message: '接收人属于区域不能为空', trigger: 'blur' }
          ],
          aceptDate: [
            { required: true, message: '接受时间不能为空', trigger: 'blur' }
          ],
          transmitDate: [
            { required: true, message: '下达时间不能为空', trigger: 'blur' }
          ],
          reportDate: [
            { required: true, message: '上报时间不能为空', trigger: 'blur' }
          ],
          reportFile: [
            { required: true, message: '上报附件不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.mainId = data.data.mainId
                this.dataForm.userId = data.data.userId
                this.dataForm.reportContent = data.data.reportContent
                this.dataForm.areaCode = data.data.areaCode
                this.dataForm.aceptDate = data.data.aceptDate
                this.dataForm.transmitDate = data.data.transmitDate
                this.dataForm.reportDate = data.data.reportDate
                this.dataForm.reportFile = data.data.reportFile
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
			  'mainId': this.dataForm.mainId,
			  'userId': this.dataForm.userId,
			  'reportContent': this.dataForm.reportContent,
			  'areaCode': this.dataForm.areaCode,
			  'aceptDate': this.dataForm.aceptDate,
			  'transmitDate': this.dataForm.transmitDate,
			  'reportDate': this.dataForm.reportDate,
			  'reportFile': this.dataForm.reportFile
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
